import React from 'react'
import { graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

// Layout
import Layout from "../../components/layout/index"
import SEO from "../../components/seo"

// Components
import Content from './content'
import Modal from './modal'

export default ({
    data: {
        wordpressWpProject: {
            acf: {
                title,
                description
            },
            featured_media
        }
    }
}) => {

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                modal ? (
                    <Modal
                        title={title}
                        description={description}
                        bg={featured_media ? featured_media.localFile.childImageSharp.fluid : false}
                        closeTo={closeTo}
                    />
                ) : (
                    <Layout>
                        <SEO title={title}/>
                        <Content 
                            title={title}
                            description={description}
                            bg={featured_media ? featured_media.localFile.childImageSharp.fluid : false}
                        />
                    </Layout>
                )
            )}
        </ModalRoutingContext.Consumer>
    )
}

// Query
export const projectQuery = graphql`
    query singleProject ($id: Int!) {
        wordpressWpProject(wordpress_id: {eq: $id}) {
            acf {
                title
                description
            }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxHeight: 1170, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`