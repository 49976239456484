import React from 'react'
import styled from 'styled-components'
import Background from 'gatsby-background-image'
import { Link } from 'gatsby'

// Assets
import Cross from '../../images/icons/cross.svg'

// Components

export default ({
    title,
    description,
    bg,
    closeTo
}) => {
    return (
        <Wrapper>
            <Header>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Header>
            <Image fluid={bg}/>
            <Close 
                as={Link} 
                to={closeTo} 
                state={{
                    noScroll: true
                }}
            />
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled.div`
    background-color:#fff;
    border-radius:0.25rem;
    overflow:hidden;
    position:relative;
    display:flex;
    flex-wrap:wrap;
    flex-direction:column-reverse;
`
const Title = styled.h1`
    font-size:1.5rem;
    color:#192e55;
    @media (min-width:992px) {
        color:#fff;
        font-size:2rem;
    }
`
const Description = styled.p`
    font-size:0.85rem;
    @media (min-width:992px) {
        color:#fff;
        font-size:1rem;   
        max-width:75%; 
    }
`
const Image = styled(Background)`
    width:100%;
    padding-top:56%;
`
const Header = styled.header`
    position:relative;
    width:100%;
    z-index:2;
    max-width:100%;
    padding:1rem;
    display:grid;
    grid-template-columns:minmax(0, 1fr);
    grid-row-gap:0.5rem;
    transition:all 0.5s;
    @media (min-width:992px) {
        position:absolute;
        bottom:0;
        left:0;
        box-shadow: inset 0px -8rem 2.6rem -3rem rgba(0,0,0,0.8);
        text-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.75);
    }
`
const Close = styled.button`
    cursor:pointer;
    background-color:rgba(255,255,255,0.85);
    background-image:url(${Cross});
    background-repeat:no-repeat;
    background-size: 0.85rem 0.85rem;
    background-position:center;
    height:2rem;
    width:2rem;
    position:absolute;
    top:0.5rem;
    right:0.5rem;
    outline:0;
    border:none;
    border-radius:50%;
    &:hover {
        background-color:rgba(255,255,255,1);
    }
    @media (min-width:992px) {
        right:1rem;
        top:1rem;
    }
`