import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

// Components
import Container from '../../components/container'

export default ({
    title,
    description,
    slug,
    bg
}) => {
    return (
        <Section>
            <Container
                sm={`
                    padding-top:3rem;
                    padding-bottom:3rem;
                `}
                lg={`
                    padding-top:4.5rem;
                    padding-bottom:4.5rem;
                `}
            >
                <h1>{title}</h1>
                <p>{description}</p>
                <Image fluid={bg}/>
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`

`